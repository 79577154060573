var columns = [{
  title: "创建时间",
  dataIndex: "createdDate",
  key: "createdDate",
  scopedSlots: {
    customRender: "createdDate"
  }
}, {
  title: "创建人",
  dataIndex: "createdName",
  key: "createdName",
  scopedSlots: {
    customRender: "createdName"
  }
}, {
  title: "打招呼",
  dataIndex: "msgName",
  key: "msgName",
  scopedSlots: {
    customRender: "msgName"
  }
}, {
  title: '操作',
  dataIndex: 'actions',
  key: 'actions',
  align: 'center',
  scopedSlots: {
    customRender: 'actions'
  }
}];
export { columns };